<template>
    <div class="content">
        <div class="btn">
            <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
            <el-upload ref="upload" :on-change="handlePreview" :show-file-list="false" accept=".xls,.xlsx" :auto-upload="false" action="#" :limit="1">
                <el-button type="primary" class="el-icon-upload2" style="margin-right:5px;margin-left:5px" > 导入</el-button>
            </el-upload>
            <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="车辆信息.xls" :fetch="createExportData" :before-finish="finishDownload" worksheet="车辆信息">
                <el-button icon="el-icon-download" type="primary">导出</el-button>
            </JsonExcel>
            <el-button type="primary" style="margin-left:5px"  class="el-icon-bottom" @click="Send"> 全部下发</el-button>
        </div>

        <el-card style="margin-top:15px;width:100%;" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="12" :sm="8" :md="6" :lg="4">
              <div class="search">
                <label>姓名</label>
                <el-input v-model="searchForm.name" class="marg" style="width:150px"></el-input>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4">
              <div class="search">
                <label>车牌号</label>
                <el-input v-model="searchForm.plateno" class="marg" style="width:150px"></el-input>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4">
              <div class="search">
                <label>编号</label>
                <el-input v-model="searchForm.no" class="marg" style="width:150px"></el-input>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4">
              <div class="search">
                <label>卡型</label>
                <el-select v-model="searchForm.cardId" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="item in filterCardDataList(null,false)" :key="item.card_id" :label="item.card_name" :value="item.card_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4">
              <div class="search">
                <label>状态</label>
                <el-select v-model="searchForm.status" style="width:150px">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="正常" :value="1"></el-option>
                    <el-option label="黑名单" :value="2"></el-option>
                    <el-option label="过期" :value="3"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="12" :sm="8" :md="6" :lg="4">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <el-table :data='$store.state.CarDataForm.CarDataList' border style="width: 100%;height: calc(100vh - 250px);max-height: calc(100vh - 250px);overflow-y: scroll;"  stripe :highlight-current-row='true'>
          <el-table-column align="center" prop="car_no" label="编号"></el-table-column>
          <el-table-column align="center" prop="car_name" label="姓名"></el-table-column>
          <el-table-column align="center" prop="car_plateno" label="车牌号"></el-table-column>
          <el-table-column align="center" prop="card_name" label="卡型"></el-table-column>
          <el-table-column align="center" prop="car_car_type" label="车型">
            <template slot-scope="scope">
              {{scope.row.car_car_type===0?'小车':'大车'}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="car_endtime" label="有效期">
            <template slot-scope="scope">
              {{FormData(scope.row.car_endtime)}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="car_save_money" label="储值余额(元)">
            <template slot-scope="scope">
              {{scope.row.car_save_money?scope.row.car_save_money:''}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="car_phone" label="联系方式"></el-table-column>
          <el-table-column align="center" prop="car_status" label="状态">
            <template slot-scope="scope">
              <span v-html="CheckStatus(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
                 <div>
                    <el-link type="primary" @click="Edit(scope.row)">修改</el-link>
                    <el-link type="danger" @click="Del(scope.row)">删除</el-link>

                    <el-dropdown  @command="Delay($event,scope.row)">
                      <span class="el-dropdown-link" style="font-size:12px;color:#409EFF;margin-right:10px">操作<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item style="color: #409EFF" command="a">延期</el-dropdown-item>
                        <el-dropdown-item style="color:red" command="b"> 退费</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown  @command="BlackListOpt($event,scope.row)">
                      <span class="el-dropdown-link" style="font-size:12px;color:#409EFF">名单<i class="el-icon-arrow-down el-icon--right"></i></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item style="color:red" command="a">设置黑名单</el-dropdown-item>
                        <el-dropdown-item style="color:#409EFF" command="b"> 解除黑名单</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @size-change="handleSizeChange" @current-change="current_change" :total="$store.state.CarDataForm.total" background :page-size="searchForm.pageSize"></el-pagination>

        <el-dialog :title="title" :visible.sync="dialogVisible" width='600px' :append-to-body='true'>
            <el-form :model="carForm" :rules="rules" ref="carForm" label-width="100px" size='mini'>
                <el-row :gutter="10">
                  <el-col :span="12">
                      <el-form-item label="编号" prop="car_no">
                        <el-input v-model="carForm.car_no"></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="姓名" prop="car_name">
                        <el-input v-model="carForm.car_name"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="固定车位数" prop="car_slot_num">
                      <el-input-number v-model="carForm.car_slot_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                   <el-col :span="10">
                      <el-form-item label="车牌号" prop="car_plateno" style="transform: translate(0, 50%)">
                        <el-popover placement="bottom" width="410" trigger="click">
                          <PlatePage ref="platePage" @childValue="valueFromChild" ></PlatePage>
                          <el-input v-model="carForm.car_plateno" slot="reference"></el-input>
                        </el-popover>
                      </el-form-item>
                   </el-col>
                    <el-col :span="3">
                      <div class="btnP">
                        <el-tooltip class="item" effect="dark" content="一位多车情况下，请使用此操作" placement="top-start">
                          <el-button type="primary" icon="el-icon-circle-plus-outline" @click="AddPlateno"></el-button>
                        </el-tooltip>
                        <el-button type="primary" style="margin:5px 0" icon="el-icon-remove-outline" @click="RemovePlateno"></el-button>
                      </div>
                    </el-col>
                    <el-col :span="11">
                      <ul class="box-plateno">
                        <li v-for="item in carForm.listCar" :key='item.plateno' :class="item.ischecked?'clickLi':'unClickLi'" @click="liplatenoClick(item)">{{item.car_plateno}}</li>
                      </ul>
                    </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-top:15px">
                  <el-col :span="12">
                    <el-form-item label="卡型">
                        <el-select v-model="carForm.car_card_type" style="width:100%;">
                          <el-option v-for="item in filterCardDataList(null,false)" :key="item.card_id" :label="item.card_name" :value='item.card_id'></el-option>
                        </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="车型">
                        <el-select v-model="carForm.car_car_type" style="width:100%;">
                          <el-option label="小车" :value='0'></el-option>
                          <el-option label="大车" :value='1'></el-option>
                        </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-top:15px">
                  <el-col :span="12">
                    <el-form-item :label="CheckCardType(carForm.car_card_type)===6?'储值余额':'有效期'">
                      <el-date-picker :readonly="carForm.car_id===0?false:true" v-if="CheckCardType(carForm.car_card_type)===0||CheckCardType(carForm.car_card_type)===5" :disabled="CheckCardType(carForm.car_card_type)===5?true:false" v-model="carForm.car_endtime" type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期" style="width:100%"></el-date-picker>
                      <el-input-number :disabled="carForm.car_id===0?false:true" v-if="CheckCardType(carForm.car_card_type)===6"  v-model="carForm.car_save_money" controls-position="right" :precision="2" :min="0" style="width:100%"></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                  </el-col>
                </el-row>
                <el-row :gutter="10" style="margin-top:15px">
                  <el-col :span="12">
                    <el-form-item label="联系方式">
                        <el-input v-model="carForm.car_phone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="备注">
                        <el-input v-model="carForm.car_remark"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible.sync="delayDialogVisible" :width='screenWidth<738?"70%":(screenWidth<1200?"50%":"35%")' :append-to-body='true'>
          <el-form :model="delayForm" :rules="rules" ref="delayForm" label-width="100px" size='medium'>
            <div v-if='delayForm.card_limit===0'>
              <el-form-item label="延期方式" >
                <div style="float:left">
                  <el-radio v-model="delayForm.delay_type" :label="0">按月延期</el-radio>
                  <el-radio v-model="delayForm.delay_type" :label="1">按截止日期延期</el-radio>
                </div>
              </el-form-item>
              <div v-if="delayForm.delay_type===0">
                <el-form-item label="延期月数">
                  <el-input-number v-model="delayForm.delay_month" controls-position="right" :min="1" style="width:100%" @change='MonthChange'></el-input-number>
                </el-form-item>
              </div>
              <el-form-item label="截止日期">
                  <el-date-picker v-model="delayForm.delay_endtime" type="date" value-format="yyyy-MM-dd HH:mm:ss" :disabled='delayForm.delay_type===0?true:false' placeholder="选择日期" style="width:100%"></el-date-picker>
              </el-form-item>
              <el-form-item label="应收金额">
                  <el-input-number v-model="delayForm.should_charge" controls-position="right" :precision="2" :disabled='true' :min="0" style="width:100%"></el-input-number>
              </el-form-item>
              <el-form-item label="实收金额">
                    <el-input-number v-model="delayForm.charge" controls-position="right" :precision="2"  :min="0" style="width:100%"></el-input-number>
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item label="储值金额">
                 <el-input-number v-model="delayForm.save_money" controls-position="right" :precision="2"  :min="0" style="width:100%"></el-input-number>
              </el-form-item>
            </div>
            <el-form-item label="备注">
                <el-input v-model="delayForm.remark"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click="delayDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Ok">确 定</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="title" :visible.sync="deChargeDialogVisible" width='35%' :append-to-body='true'>
          <el-form :model="delayForm" :rules="rules" ref="delayForm" label-width="100px" size='medium'>
            <el-form-item label="退费金额" prop="charge">
                <el-input-number v-model="delayForm.charge" controls-position="right" :precision="2"  :min="0" style="width:100%"></el-input-number>
            </el-form-item>
            <el-form-item v-if="delayForm.card_limit===0" label="新截止期">
               <el-date-picker v-model="delayForm.delay_endtime" type="date" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期" style="width:100%"></el-date-picker>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
                <el-button @click="deChargeDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Save">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'
import JsonExcel from 'vue-json-excel'
import { read, utils } from 'xlsx'
import {mapGetters} from 'vuex'
import PlatePage from '../../components/Plateno.vue'

export default {
  data: function () {
    return {
      screenWidth: 0,
      searchForm: {
        name: '',
        plateno: '',
        no: '',
        cardId: 0,
        status: 0,
        carCode: 0,

        pageSize: 15,
        pageNo: 1
      },

      dialogVisible: false,
      title: '',
      carForm: {
        car_id: 0,
        car_no: '',
        car_name: '',
        car_car_type: 0,
        car_card_type: 0,
        car_slot_num: 0,
        car_plateno: '',
        car_endtime: new Date(),
        car_phone: '',
        car_remark: '',
        car_code: 0,
        car_save_money: 0.00,
        car_status: 1,
        listCar: [],
        delListCar: []// 被删除的车辆信息
      },
      rules: {
        car_no: [{ required: true, message: '请输入人员编号', trigger: 'blur' }],
        car_name: [{ required: true, message: '请输入人员姓名', trigger: 'blur' }],
        charge: [{ required: true, message: '请输入退费金额', trigger: 'blur' }]
      },
      delayDialogVisible: false,
      delayForm: {
        opt_type: 1,
        car_id: 0,
        car_plateno: '',
        car_no: '',
        card_type: 0,
        card_limit: 0,
        delay_type: 0,
        delay_month: 1,
        old_time: '',
        delay_endtime: '',
        should_charge: 0.00,
        charge: 0.00,
        old_save_money: 0,
        save_money: 0.00,
        remark: ''
      },
      deChargeDialogVisible: false,
      isFinish: true,
      // 导出
      exportDataStandard: {
        编号:'car_no',
        姓名:'car_name',
        车辆组:'car_code',
        车牌号:'car_plateno',
        固定车位数:'car_slot_num',
        卡型:'card_name',
        车型:{
          field: 'car_car_type',
          callback: value => {
            return value===0?'小车':'大车'
          }
        },
        有效期:{
          field: 'car_endtime',
          callback: value => {
            return this.FormData(value)
          }
        },
        储值余额:{
          field: 'car_save_money',
          callback: value => {
            return value?value:''
          }
        },
        联系方式:'car_phone',
        状态:{
          field: 'car_status',
          callback: value => {
            return value===1?'正常':(value===2?'黑名单':'')
          }
        },
        备注:'car_remark'
      },
      exportData: []
    }
  },
  components: {
    JsonExcel,
    PlatePage
  },
  created () {
    this.screenWidth = document.body.clientWidth

    if(this.$store.state.CardDataList.length===0){
        this.$store.dispatch('getCardDataList')
    }
    if(this.$store.state.CarDataForm.CarDataList.length===0){
        this.$store.dispatch('getCarDataList', this.searchForm)
    }
  },
  computed: {
    ...mapGetters(['filterCardDataList']),
    CheckCardType () {
      return function (id) {
        var limit = 0
        if (id !== undefined) {
          var arr = this.filterCardDataList(id)
          if (arr.length > 0) { limit = arr[0].card_limit }
        }
        return limit
      }
    },
    FormData () {
      return function (time) {
        if (!time) { return '' } else { return util.FormateDate(time, 0) }
      }
    },
    CheckStatus () {
      return function (row) {
        var label = '<span style="color:#7DC23A;font-size:12px">正常</span>'
        if (row.car_status === 2) {
          label = '<span style="color:red;font-size:12px">黑名单</span>'
        } else {
          if (row.car_endtime !== null && row.car_endtime !== '' && row.car_endtime !== undefined) {
            var time = new Date()
            var t = new Date(row.car_endtime)
            if (t < time) {
              label = '<span style="color:#FDCE8B;font-size:12px">过期</span>'
            }
          }
        }
        return label
      }
    }
  },
  methods: {
    AddClick () {
      if(util.CheckUserAuth('2-2-1')){
        this.title = '新增'
        this.dialogVisible = true

        this.carForm.car_no = ''
        util.Get('car/getcarno').then(res => {
          if (res.rpStatus === 10000) {
            this.carForm.car_no = res.rpMsg
          }
        })

        this.carForm.car_name = ''

        this.carForm.car_card_type = this.filterCardDataList(null,false)[0].card_id
        this.carForm.car_id=0
        this.carForm.car_code = 0
        this.carForm.car_car_type = 0
        this.carForm.car_slot_num = 0
        this.carForm.car_plateno = this.$store.state.CarDataForm.default_short_plateno
        var date = new Date()
        date.setMonth(date.getMonth() + 1)
        this.carForm.car_endtime = new Date(date)
        this.carForm.car_phone = ''
        this.carForm.car_remark = ''
        this.carForm.car_save_money = 0.00
        this.carForm.listCar = []
        this.$refs.platePage.refreshNo(this.carForm.car_plateno)
      }
    },
    
    valueFromChild(value){
      this.carForm.car_plateno=value
    },
    
    // 导入
    handlePreview (file, fileList) {
      if(util.CheckUserAuth('2-2-4')){
        if (/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
          const filereader = new FileReader()
          filereader.onload = ev => {
            try {
              const data = ev.target.result
              const workbook = read(data, { type: 'binary' })
              var sheet = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

              if (sheet.length > 0) {
                var params = []
                sheet.forEach(a => {
                  if(a.有效期!==null&&a.有效期!==undefined&&a.有效期!==''){
                      var numb=a.有效期
                      const time = new Date((numb - 1) * 24 * 3600000 + 1);
                      let h = time.getHours() + 16;
                      let yeraData = new Date(1900, 0, numb - 1)
                      let year = yeraData.getFullYear();
                      let month = yeraData.getMonth() + 1
                      month = month < 10 ? '0' + month : month;
                      let day = yeraData.getDate()
                      day = day < 10 ? '0' + day : day;
                      if (h > 23) {
                          h = h - 24;
                      }
                      let m = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
                      let s = time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
                      a.有效期=`${year}-${month}-${day}`
                  }
                  params.push(a)
                })
                util.Post('car/uploadfromexcel', params).then(res => {
                  if (res.rpStatus === 10000) {
                    this.$message.success('导入成功，正下发车辆信息')
                    this.Send()
                    this.$store.dispatch('getCarDataList', this.searchForm)
                  } else {
                    this.$message.error(res.rpMsg)
                  }
                })
                this.$refs.upload.clearFiles()
              }
            } catch (e) {
              this.$message.error('上传数据错误:' + e)
            }
          }
          console.log(file.raw)
          filereader.readAsBinaryString(file.raw)
          return true
        }
        else{
          this.$message.error('上传格式不正确，请上传xls或者xlsx格式')
          return false
        }
      }
    },

    // 导出
    async createExportData () {
      if(util.CheckUserAuth('2-2-5')){
        this.searchForm.pageNo = 0
        var form = JSON.parse(JSON.stringify(this.searchForm))
        form.pageNo = 0

        var result = await util.Get('car/getcardataList', form)
        if (result.rpStatus === 10000) {
          this.exportData = result.list[0]
          return this.exportData
        }
      }
    },
    finishDownload () {
      this.$message({
        message: '导出数据成功',
        type: 'success'
      })
      this.exportData=[]
    },

    current_change (page) {
      this.searchForm.pageNo = page
      this.$store.dispatch('getCarDataList', this.searchForm)
    },
    handleSizeChange(pageSize){
      this.searchForm.pageSize=pageSize
      this.searchForm.pageNo=1
      this.$store.dispatch('getCarDataList', this.searchForm)
    },
    Search () {
      this.searchForm.carCode = 0
      this.searchForm.pageNo = 1
      this.$store.dispatch('getCarDataList', this.searchForm)
    },
    AddPlateno () {
      if (this.carForm.car_card_type === undefined) {
        this.$message.error('请选择卡型')
        return
      }
      if (this.carForm.car_plateno !== '') {
        if(this.carForm.car_plateno.length<7){
          this.$message.error('请输入正确的车牌号')
          return
        }
        if (this.carForm.listCar === undefined) {
          this.carForm.listCar = []
        }
        var arr = this.carForm.listCar.filter(a => a.car_plateno === this.carForm.car_plateno)
        if (arr.length === 0) {
          var form = {
            car_id: this.carForm.car_id,
            car_plateno: this.carForm.car_plateno.toUpperCase(),
            car_card_type: this.carForm.car_card_type,
            car_car_type: this.carForm.car_car_type,
            car_endtime: this.carForm.car_endtime,
            car_save_money: this.carForm.car_save_money,
            car_status: this.carForm.car_status,
            ischecked: false
          }
          this.carForm.listCar.push(form)
          this.carForm.car_id = 0
          this.carForm.car_plateno = this.$store.state.CarDataForm.default_short_plateno
          this.$refs.platePage.refreshNo(this.carForm.car_plateno)
        }
      }
    },
    RemovePlateno () {
      this.carForm.listCar.forEach(a => {
        if (a.ischecked) {
          if (a.car_id !== 0) {
            this.carForm.delListCar.push(a)
          }
          this.carForm.listCar.splice(this.carForm.listCar.indexOf(a), 1)
        }
      })
    },
    liplatenoClick (item) {
      this.carForm.listCar.forEach(a => {
        a.ischecked = false
        if (a.car_plateno === item.car_plateno) {
          a.ischecked = true
        }
      })
    },
    submit () {
      this.$refs.carForm.validate((valid) => {
        if (valid) {
          if (this.carForm.car_plateno.length < 7 && this.carForm.listCar.length === 0) {
            this.$message.error('请输入车辆信息')
            return
          }
          util.Post('car/edit', this.carForm).then(res => {
            if (res.rpStatus === 10000) {
              this.searchForm.carCode = 0
              this.searchForm.pageNo=1
              this.$store.dispatch('getCarDataList', this.searchForm)
              this.dialogVisible = false
              this.$message.success('保存并下发成功')
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    Edit (row) {
      if(util.CheckUserAuth('2-2-2')){
        this.carForm = JSON.parse(JSON.stringify(row))
        this.carForm.delListCar = []
        // 获取该人员名下的所有车辆信息
        util.Get('car/getcardatalist', {
          carCode:row.car_code,
          pageNo:1,
          pageSize:this.searchForm.pageSize
        }).then(res => {
          if (res.rpStatus === 10000) {
            if (res.list[0].length > 1) {
              var list = res.list[0].filter(a => a.car_plateno !== row.car_plateno)
              var arr = []
              list.forEach(a => {
                var form = {
                  car_id: a.car_id,
                  car_plateno: a.car_plateno.toUpperCase(),
                  car_card_type: a.car_card_type,
                  car_car_type: a.car_car_type,
                  car_endtime: a.car_endtime,
                  car_save_money: a.car_save_money,
                  car_status: a.car_status,
                  ischecked: false
                }
                arr.push(form)
              })
              this.$set(this.carForm, 'listCar', arr)
            }
          }
        })
        this.dialogVisible = true
        this.title = '修改'
      }
    },
    Delay (command, row) {
      var arr = this.filterCardDataList(row.car_card_type)
      if (arr[0].card_limit === 5) {
        this.$message.error(command === 'a' ? '贵宾车无需延期' : '贵宾车无需退费')
        return
      }

      this.delayForm.card_limit = arr[0].card_limit
      this.delayForm.car_id = row.car_id
      this.delayForm.car_plateno = row.car_plateno
      this.delayForm.car_no = row.car_no
      this.delayForm.card_type = row.car_card_type
      this.delayForm.old_time = row.car_endtime

      if (command === 'a') {
        if(util.CheckUserAuth('2-2-6')){
          this.title = '充值延期'
          this.delayDialogVisible = true
          this.delayForm.opt_type = 1

          if (arr[0].card_limit === 0) {
            this.delayForm.delay_type = 0
            var t = new Date(row.car_endtime)
            if (t < new Date()) {
              t = new Date()
            }
            t.setMonth(t.getMonth() + 1)
            this.delayForm.delay_endtime = new Date(t)
            this.delayForm.delay_month = 1
            this.delayForm.should_charge = arr[0].card_month_charge
            this.delayForm.charge = arr[0].card_month_charge
            this.delayForm.remark = ''
          }
          this.delayForm.save_money = 0.00
        }
      } else {
        if(util.CheckUserAuth('2-2-7')){
          this.deChargeDialogVisible = true
          this.title = '退费'
          this.delayForm.opt_type = 2
          this.delayForm.old_save_money = row.car_save_money
          if (arr[0].card_limit === 0) {
            this.delayForm.delay_endtime = new Date(row.car_endtime)
          }
        }
      }
    },
    MonthChange (currentValue, oldValue) {
      var t = new Date(this.delayForm.old_time)
      if (t < new Date()) {
        t = new Date()
      }
      t.setMonth(t.getMonth() + currentValue)
      this.delayForm.delay_endtime = new Date(t)
      var value = (this.delayForm.should_charge / oldValue) * currentValue
      this.delayForm.should_charge = value
      this.delayForm.charge = value
    },
    // 延期
    Ok () {
      if (this.delayForm.card_limit === 0) {
        var t1 = new Date(this.delayForm.old_time)
        if (t1 >= this.delayForm.delay_endtime) {
          this.$message.error('延期后的日期不能小于之前的日期')
          return
        }
      } else {
        if (this.delayForm.save_money === 0) {
          this.$message.error('请输入储值的金额')
          return
        }
      }
      util.Post('car/delay', this.delayForm).then(res => {
        if (res.rpStatus !== 10000) {
          this.$message.error(res.rpMsg)
        }
        else{
          this.$message.success( '延期成功')
        } 
        this.$store.dispatch('getCarDataList', this.searchForm)
        this.delayDialogVisible = false
      })
    },
    // 退费
    Save () {
      this.$refs.delayForm.validate((valid) => {
        if (valid) {
          if (this.delayForm.card_limit === 0) {
            var t = new Date(this.delayForm.old_time)
            var t2 = new Date(this.delayForm.delay_endtime)
            if (t < t2) {
              this.$message.error('退费后的截止有效期大于原有效期')
              return
            }
          } else {
            if (this.delayForm.old_save_money < this.delayForm.charge) {
              this.$message.error('退费金额超出原有金额')
              return
            }
          }
          this.delayForm.save_money = this.delayForm.charge
          util.Post('car/delay', this.delayForm).then(res => {
            if (res.rpStatus === 10000 || res.rpStatus === 10002) {
              this.deChargeDialogVisible = false
              this.$store.dispatch('getCarDataList', this.searchForm)
              if (res.rpStatus === 10002) {
                this.$message.error(res.rpMsg + ',同步数据失败')
              }
              else{
                this.$message.success( '退费成功')
              } 
            }
          })
        }
      })
    },
    Del (row) {
      if(util.CheckUserAuth('2-2-3')){
        this.$confirm('确定删除车辆“' + row.car_plateno + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          util.Get('car/del?id=' + row.car_id + '&plateno=' + row.car_plateno).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getCarDataList', this.searchForm)
               this.$message.success('删除车辆“' + row.car_plateno + '”成功')
            } else { this.$message.error(res.rpMsg) }
          })
        }).catch(e => e)
      }
    },
    BlackListOpt (command, row) {
      if (command === 'a') {
        if(util.CheckUserAuth('2-2-8')){
          if (row.car_status !== 2) {
            this.$confirm('确定设置车辆“' + row.car_plateno + '”黑名单？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              util.Post('car/blacklistopt?id=' + row.car_id + '&status=2&plateno=' + row.car_plateno).then(res => {
                if (res.rpStatus !== 10000) {
                  this.$message.error(res.rpMsg)
                } else {
                  this.$message.success('设置黑名单成功')
                  this.$store.dispatch('getCarDataList', this.searchForm)
                }
              })
            }).catch(e => e)
          }
        }
      } else {
        if(util.CheckUserAuth('2-2-9')){
          if (row.car_status !== 1) {
            util.Post('car/blacklistopt?id=' + row.car_id + '&status=1&plateno=' + row.car_plateno).then(res => {
              if (res.rpStatus !== 10000) {
                this.$message.error(res.rpMsg)
              } else {
                this.$message.success('解除黑名单成功')
                this.$store.dispatch('getCarDataList', this.searchForm)
              }
            })
          }
        }
      }
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('car').then(res=>{
          this.isFinish=true
        })
      }
    }
  },
  watch:{
    'carForm.car_plateno'(newValue,oldValue){
      this.$refs.platePage.refreshNo(newValue)
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.btn{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.el-table{
    margin-top: 10px;
}
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 50px;
}
.btnP{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0px;
}
.box-plateno{
  border: 1px solid;
    border-radius: 6px;
    height: 70px;
    margin: 0;
    display: block;
    overflow:scroll;
    overflow-x: hidden
}
ul,li{
list-style:none;
  margin:5px;
 padding:0px;
}
li{
 cursor: pointer;
}
.clickLi{
  background-color: skyblue;
}
.unClickLi{
  background-color: white;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
