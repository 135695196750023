<template>
    <div>
        <div class="tool">
            <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>
            <el-button type="primary" class="el-icon-bottom" @click="Send"> 全部下发</el-button>
        </div>

        <treetable style='margin-top: 15px;' :data='changeParkDataToTreeData' :columns='columns' border tree-type :is-fold='false' :selection-type='false' :show-index='false' :expand-type='false'>
            <template slot="parknum" slot-scope="scope">
                {{ComputedParkNum(scope.row)}}
            </template>
            <template slot="qfnum" slot-scope="scope">
                {{ComputedQFNum(scope.row)}}
            </template>
            <template slot="handleEvent" slot-scope="scope">
              <el-link type="primary" @click="Edit(scope.row)">修改</el-link>
              <el-link type="danger" @click="Del(scope.row)">删除</el-link>
            </template>
        </treetable>

         <el-dialog :title="title" :visible.sync="dialogVisible" width='800px' :append-to-body='true'>
             <el-form :model="parkForm" :rules="rules" ref="parkForm" label-width="190px" size='medium'>
                <div class="content">
                    <div>
                        <el-form-item label="车场名称" prop="pk_name">
                            <el-input v-model="parkForm.pk_name"></el-input>
                        </el-form-item>
                        <el-form-item label="所属车场">
                            <el-select v-model="parkForm.pk_sub_id" style="width:100%;">
                                <el-option label="无" :value='0'></el-option>
                                <el-option v-for="item in CheckMainPark" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="区分车位">
                            <el-select v-model="parkForm.pk_qf_num" style="width:100%;">
                                <el-option label="不区分" :value='0'></el-option>
                                <el-option label="区分" :value='1'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label="parkForm.pk_qf_num===0?'总车位':'长期车位'">
                            <el-input-number v-model="parkForm.pk_longcard_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item v-if="parkForm.pk_qf_num===1" label="临时车位">
                            <el-input-number v-model="parkForm.pk_tempcard_num" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="Led显示余位">
                            <el-select v-model="parkForm.pk_led_show_yw" style="width:100%;">
                                <el-option label="总余位" :value='0'></el-option>
                                <el-option v-if="parkForm.pk_qf_num===1" label="临时车余位" :value='1'></el-option>
                                <el-option v-if="parkForm.pk_qf_num===1" label="长期车余位" :value='2'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="长期车占用车位">
                          <el-select v-model="parkForm.pk_longcard_occupy" style="width:100%;">
                              <el-option label="占用" :value='0'></el-option>
                              <el-option label="不占用" :value='1'></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="默认车牌" prop="pk_default_plateno">
                            <el-input v-model="parkForm.pk_default_plateno"></el-input>
                        </el-form-item>
                        <el-form-item label="一位多车处理">
                            <el-select v-model="parkForm.pk_morecar_opt" style="width:100%;">
                                <el-option label="车位占满,按长期车处理" :value='0'></el-option>
                                <el-option label="车位占满,禁止进" :value='1'></el-option>
                                <el-option label="车位占满,按临时车处理" :value='2'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="车位满时">
                          <el-select v-model="parkForm.pk_full_opt" style="width:100%;">
                              <el-option label="禁止进(固定车位除外)" :value='0'></el-option>
                              <el-option label="允许进" :value='1'></el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item label="追缴欠费" >
                        <el-select v-model="parkForm.pk_recovery_fee" style="width:100%;">
                          <el-option label="否" :value='0'></el-option>
                          <el-option label="自动追缴" :value='1'></el-option>
                          <el-option label="人工审核" :value='2'></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div style="margin-left: 20px;">
                        <el-form-item label="长期车宽限期(天)" >
                            <el-input-number v-model="parkForm.pk_longcard_overtime_len" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="长期车逾期" >
                            <el-select v-model="parkForm.pk_longcard_overtime_opt" style="width:100%;">
                                <el-option label="按临时车处理" :value='0'></el-option>
                                <el-option label="禁止进，按临时车出" :value='1'></el-option>
                                <el-option label="禁止进，免费出" :value='2'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="长期车到期提醒(天)" >
                            <el-input-number v-model="parkForm.pk_longcard_remind_times" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="储值车余额提醒(元)" >
                            <el-input-number v-model="parkForm.pk_stored_card_remind_charge" :precision="2" :step="1" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="预留缴费出场时长(分钟)" >
                            <el-input-number v-model="parkForm.pk_reserve_pay_timelen"  controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="同一车辆进出间隔(秒)" >
                            <el-input-number v-model="parkForm.pk_inout_interval" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="场内预付免费出场时长(分钟)" >
                            <el-input-number v-model="parkForm.pk_center_charge_free_times" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="重复出匹配记录(天)" >
                            <el-input-number v-model="parkForm.pk_again_log_match" controls-position="right" :min="0" style="width:100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="预约车车位">
                          <el-select v-model="parkForm.pk_reserve_opt" style="width:100%;">
                              <el-option label="无车位限制,车位满仍允许进" :value='0'></el-option>
                              <el-option label="有车位限制,预约时段预留车位" :value='1'></el-option>
                              <el-option label="有车位限制,不为预约车预留车位" :value='2'></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="预约车计费">
                          <el-select v-model="parkForm.pk_reserve_pay" style="width:100%;">
                              <el-option label="正常计费" :value='0'></el-option>
                              <el-option label="预约时段内免费出，超出后正常计费" :value='1'></el-option>
                              <el-option label="免费出" :value='2'></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="军警车自动放行">
                          <el-select v-model="parkForm.pk_enabel_model" style="width:100%;">
                              <el-option label="是" :value='1'></el-option>
                              <el-option label="否" :value='0'></el-option>
                          </el-select>
                        </el-form-item>
                    </div>
                </div>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import ZkTable from 'vue-table-with-tree-grid'
import util from '../../util/util'
Vue.component('treetable', ZkTable)
import {mapGetters} from 'vuex'


export default {
  data: function () {
    return {
      dialogVisible: false,
      title: '',
      columns: [
        {
          label: '名称',
          prop: 'pk_name'
        },
        {
          label: '总车位数',
          type: 'template',
          template: 'parknum'
        },
        {
          label: '区分车位',
          type: 'template',
          template: 'qfnum'
        },
        {
          label: '操作',
          type: 'template',
          template: 'handleEvent'
        }
      ],
      parkForm: {
        pk_id: 0,
        pk_sub_id: 0,
        pk_name: '',
        pk_qf_num: 0,
        pk_longcard_num: 100,
        pk_tempcard_num: 100,
        pk_led_show_yw: 0,
        pk_longcard_occupy:0,
        pk_default_plateno: '粤B',
        pk_morecar_opt: 0,
        pk_longcard_overtime_len: 2,
        pk_longcard_overtime_opt: 0,
        pk_longcard_remind_times: 2,
        pk_stored_card_remind_charge: 10.00,
        pk_reserve_pay_timelen: 3,
        pk_inout_interval: 10,
        pk_center_charge_free_times: 15,
        pk_again_log_match: 3,
        pk_enabel_model: 0,
        pk_reserve_opt:0,
        pk_full_opt:0,
        pk_recovery_fee:0,
        pk_reserve_pay:0
      },
      rules: {
        pk_name: [{ required: true, message: '请输入车场名称', trigger: 'blur' }]
      },
      isFinish: true
    }
  },
  created () {
    if(this.$store.state.ParkDataList.length===0){
      this.$store.dispatch('getParkDataList')
    }
  },

  computed: {
    ...mapGetters(['changeParkDataToTreeData']),
    CheckSubPark(){
      return function(id){
        return this.$store.state.ParkDataList.filter(a => a.pk_sub_id === id)
      }
    },
    CheckMainPark(){
      return this.$store.state.ParkDataList.filter(a => a.pk_sub_id === 0)
    },
    ComputedParkNum () {
      return function (park) {
        var num = 0
        if (park.pk_qf_num === 0) {
          num = park.pk_longcard_num
        } else {
          num = park.pk_longcard_num
          if (park.pk_tempcard_num !== null && park.pk_tempcard_num !== undefined) { num += park.pk_tempcard_num }
        }
        return num
      }
    },
    ComputedQFNum () {
      return function (park) {
        var t = '不区分'
        if (park.pk_qf_num === 1) {
          t = '区分'
        }
        return t
      }
    }
  },
  methods: {
    AddClick () {
      if(util.CheckUserAuth('1-2-1')){
        this.dialogVisible = true
        this.title = '新增'
        this.parkForm.pk_id = 0
        this.parkForm.pk_sub_id = 0
        this.parkForm.pk_name = ''
        this.parkForm.pk_qf_num = 0
        this.parkForm.pk_longcard_num = 100
        this.parkForm.pk_tempcard_num = 100
        this.parkForm.pk_led_show_yw = 0
        this.parkForm.pk_morecar_opt = 0
        this.parkForm.pk_longcard_occupy=0
        this.parkForm.pk_default_plateno = '粤B'
        this.parkForm.pk_longcard_overtime_len = 2
        this.parkForm.pk_longcard_overtime_opt = 0
        this.parkForm.pk_longcard_remind_times = 2
        this.parkForm.pk_stored_card_remind_charge = 10.00
        this.parkForm.pk_reserve_pay_timelen = 3
        this.parkForm.pk_inout_interval = 10
        this.parkForm.pk_center_charge_free_times = 15
        this.parkForm.pk_again_log_match = 3
        this.parkForm.pk_enabel_model = 0
        this.parkForm.pk_full_opt=0
        this.parkForm.pk_recovery_fee=0
        this.parkForm.pk_reserve_pay=0
      }
    },
    Edit (row) {
      if(util.CheckUserAuth('1-2-2')){
        var data = JSON.parse(JSON.stringify(row))
        this.title = '修改'
        this.dialogVisible = true
        this.parkForm = data
      }
    },
    submit () {
      this.$refs.parkForm.validate((valid) => {
        if (valid) {
          if (this.parkForm.pk_id !== 0 && this.parkForm.pk_sub_id === this.parkForm.pk_id) {
            this.$message.error('车场不能从属自己')
            return
          }

          util.Post('park/edit', this.parkForm).then(res => {
            if (res.rpStatus === 10000) {
              this.$store.dispatch('getParkDataList')
              this.dialogVisible = false
            } else { this.$message.error(res.rpStatus) }
          })
        }
      })
    },
    Del (row) {
      if(util.CheckUserAuth('1-2-3')){
        this.$confirm('确定删除车场“' + row.pk_name + '”？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var arr = this.CheckSubPark(row.pk_id)
          if (arr.length > 0) {
            this.$message.error('该车场下有子车场，请删除子场后再删除')
            return
          }

          util.Get('park/del?id=' + row.pk_id+'&name='+row.pk_name).then(res => {
            if (res.rpStatus === 10000) {
              this.$message.success('删除车场成功')
              this.$store.dispatch('getParkDataList')
            } else { this.$message.error(util.ErrorMsg(res.rpStatus)) }
          })
        }).catch(e => e)
      }
    },
    Send () {
      if (this.isFinish) {
        this.isFinish=false
        util.Send('park').then(res=>{
          this.isFinish=true
        })
      }
    }
  }
}
</script>

<style scoped>
.tool{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>
